<template>
  <div class="list">
    <Breadcrumb name1="提取记录" name2="数据列表" />
    <el-card>
      <!-- 按钮搜索/添加区域 -->
      <!-- <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="jumpToAddSeries">添加系列</el-button>
        </el-col>
      </el-row> -->
      <!-- table区域 -->
      <el-row>
        <el-table :data="goodsList" border style="width: 100%">
          <el-table-column prop="id" label="ID" width="70"></el-table-column>
          <el-table-column prop="name" label="作品名称" width="160"></el-table-column>
          <el-table-column prop="art_entity_id" label="TokenID"></el-table-column>
          <el-table-column prop="user_id" label="用户ID"></el-table-column>
          <el-table-column prop="wallet_address" label="钱包地址" width="380"></el-table-column>
          <el-table-column prop="ex_status" label="状态" :formatter="formatExStatus"></el-table-column>
          <el-table-column prop="created_on" label="创建时间" width="200">
            <template v-slot="scope">
              {{ scope.row.created_on*1000 | dataFormat }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template v-slot="scope">
              <el-button v-if="scope.row.ex_status===0&&scope.row.data_type==2"
                type="primary"
                size="mini"
                icon="el-icon-circle-check"
                @click="updateData(scope.row.id, 1)"
              >确认</el-button>
              <el-button v-else
                type="primary"
                size="mini"
                icon="el-icon-circle-check"
                disabled
              >确认</el-button>
              <el-button  v-if="scope.row.ex_status===0&&scope.row.data_type==2"
                type="danger"
                size="mini"
                icon="el-icon-circle-close"
                @click="updateData(scope.row.id, 2)"
              >拒绝</el-button>
              <el-button v-else
                type="danger"
                size="mini"
                icon="el-icon-circle-close"
                disabled
              >拒绝</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'List',
  components: {
    Breadcrumb,
  },
  data() {
    return {
      generateLoading:false,
      // 查询参数对象
      queryInfo: {
        page: 1,
        page_size: 10,
      },
      // 艺术品列表
      goodsList: [],
      // 总数据条数
      total: 0,
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    formatExStatus:function(row){
      if (row.ex_status == 0) {
        return '提取中' //根据自己的需求设定
      } else if(row.ex_status == 1) {
        return '成功'
      }else if(row.ex_status == 2) {
        return '失败'
      }else{
        return '未知'
      }
    },
    // 根据分页获取列表数据
    getDataList() {
      const _that = this;
      _that.$http({
        method: 'get',
        url: '/api/v1/admin/extract-list',
        params: _that.queryInfo,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          _that.$message.success('获取提取列表成功!')
          _that.goodsList = res.data.list
          _that.total = res.data.pager.total_rows
        })
        .catch((res) => {
          return _that.$message.error('获取提取列表失败!')
        })
    },
    // 显示的页数改变
    handleSizeChange(newSize) {
      this.queryInfo.page_size = newSize
      this.getDataList()
    },
    // 页码的改变
    handleCurrentChange(newValue) {
      this.queryInfo.page = newValue
      this.getDataList()
    },
    async updateData(id, status){
      var msg = '提取';
      if(status == 2){
        msg = '拒绝';
      }
      const confirmResult = await this.$confirm(
        '确认'+msg+'?',
        '确认',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        this.$message.info('操作已取消!')
        return
      }
      this.$http({
        method: 'put',
        url: '/api/v1/admin/extract',
        data: {id:id,ex_status:status},
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      .then((res) => {
        if (res.data.status == 200){
          this.$message.success(msg + '成功!')
        }
        this.getDataList()
      })
      .catch((res) => {
        return this.$message.error(msg + '失败!')
      })
    },

  },
}
</script>

<style lang="less" scoped>
.el-table {
  margin: 15px 0 0;
}
</style>
<style>
  .el-dialog{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0!important;
    transform: translate(-50%, -50%);
  }
</style>